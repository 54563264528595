import { css } from 'styled-components'
import { useIntl } from 'react-intl'

const wrapper = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Custom404() {
  const intl = useIntl()

  return (
    <div css={wrapper}>
      <div>{intl.locale === 'de' ? <h1>404 - Seite nicht gefunden</h1> : <h1>404 - Page Not Found</h1>}</div>
    </div>
  )
}
